import axios from "@/plugins/axios.js"

const  actions = {
  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit, state }, force = false) {
    if(!force && state.users.length) return
    return new Promise((resolve, reject) => {
      axios.get("/users")
        .then((response) => {
          if(response.data.success){
            commit('SET_USERS', response.data.data.users)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit, state }, force = false) {
    if(!force && state.roles.length) return
    return new Promise((resolve, reject) => {
      axios.get("/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.data.roles)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.data.user)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  multipleAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(payload.action, payload.formData)
        .then((response) => {
          if(response.data.success && payload.update){
            commit('SET_USER', response.data.data.user)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchChats({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/chats/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER_CHATS', response.data.data.chats)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}


const getters  = {
  getChats: (state, getters, rootState) => {
      return state.chats.filter((ct) => ct.content.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
  },
}



const mutations = {
  ADD_USER(state, user) {
    state.users.push(user)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_USER(state, user) {
    state.user = user
    if(state.users.length){
        const dataIndex = state.users.findIndex((u) => u.id == user.id);
        Object.assign(state.users[dataIndex], user);
    }
  },
  DELETE_USER(state, userId) {
      state.user = {}
      const userIndex = state.users.findIndex((u) => u.id == userId)
      state.users.splice(userIndex, 1)
  },

  SET_USER_CHATS(state, chats) {
    state.chats = [...chats]
  },
  ADD_USER_CHAT(state, chat) {
    state.chats.push(chat)
  },
  UPDATE_USER_CHAT(state, chat){
    const dataIndex = state.chats.findIndex((f) => f.id == chat.id)
    Object.assign(state.chats[dataIndex], chat)
  },
  DELETE_USER_CHAT(state, chatId){
    const ItemIndex = state.chats.findIndex((item) => item.id == chatId)
    state.chats.splice(ItemIndex, 1);
  },
  SET_CHAT_SEARCH_QUERY(state, query) {
    state.chatSearchQuery = query
  },
  MARK_SEEN_ALL_CHATS(state, payload) {
    payload.chatData.forEach((msg) => {
      msg.is_read = 1
    })
  },
}

const state = {
  users: [],
  roles: [],
  user: {},
  chats:[],
  chatSearchQuery: ""
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

